import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import SeparatorLine from '../common/SeparatorLine';
import CollapsibleCard from '../components/CollapsibleCard';
import ActionCard from '../components/ActionCard';
import zillowLogo from '../images/zillow-logo.svg';
import truliaLogo from '../images/trulia-logo.svg';
import hotpadsLogo from '../images/hotpads-logo.svg';
import streeteasyLogo from '../images/streeteasy-logo.svg';
import landingHeroImage from '../images/landing-hero-image.jpg';
import SigninContext, { ACTIONS } from '../contexts/SigninContext';

import {
    COLLECT_INFO_CARD,
    USE_INFO_CARD,
    SHARE_INFO_CARD,
    MANAGE_PRIVACY_PREFERENCES_ACTION_CARD,
    EXPORT_DATA_ACTION_CARD,
    DELETE_DATA_ACTION_CARD,
    UPDATE_YOUR_DATA_ACTION_CARD,
} from '../constants/LandingPageConstants';
import { PageWrapper, PageMainContent, PageSection } from '../common/Page';
import {
    Flex,
    Heading,
    Spacer,
    Paragraph,
    Text,
    spaceMixin,
    mediaBreakpointMixin,
} from '@zillow/constellation';
import { NavLink, withRouter } from 'react-router-dom';

const StyledHeading = styled(Heading)`
    font-size: 40px;
    padding: 0 ${spaceMixin('md')};
    text-align: center;
`;

const StyledMainHeading = styled(StyledHeading)`
  font-size: 60px;
  @media ${mediaBreakpointMixin('lg')} {
    margin: 80px
    margin-bottom: 0;
  }
`;

const StyledLogoFlex = styled(Flex)`
    flex-wrap: wrap;
    > img {
        width: 26%;
        margin: 10px 0;
    }

    @media ${mediaBreakpointMixin('lg')} {
      > img {
        width: 12%;
        min-width: 130px;
        margin: 0;
    }
`;

const StyledHeroFlex = styled(Flex)`
    border-radius: 3px;
    background-color: #fff;
    margin: 30px 0;
    flex-wrap: wrap;
    > * {
        width: 100%;
        &.privacy-hero-image {
            background-image: url(${props => props.image});
            background-size: cover;
            background-position: center;
            height: 290px;
        }
    }

    @media ${mediaBreakpointMixin('lg')} {
        margin: 70px auto 0 auto;
        max-width: 1200px;
        > * {
            width: 50%;
            &.privacy-hero-description {
                padding: ${spaceMixin('lg')};
            }
            &.privacy-hero-image {
                height: auto;
                order: 1;
            }
        }
    }
`;

const StyledActionCardsFlex = styled(Flex)`
    width: 100%;
    flex-direction: column;

    @media ${mediaBreakpointMixin('lg')} {
        margin: 0 auto;
        max-width: 1080px;
        flex-direction: row;
        justify-content: space-evenly;
        flex-grow: 1;
        flex-basis: 0;
        > div {
            width: 32%;
        }
    }
`;

const StyledParagraph = styled(Paragraph)`
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    margin: 0 ${spaceMixin('md')};

    @media ${mediaBreakpointMixin('lg')} {
        max-width: 610px;
        margin: ${spaceMixin('lg')} auto;
        margin-top: 0;
    }
`;

const StyleReadFullPolicyWrapper = styled.div`
    text-align: center;
`;

const openSigninModalWithRedirectTo = (dispatch, redirectTo) => () => {
    dispatch({
        type: ACTIONS.OPEN_SIGNIN_MODAL,
        payload: {
            redirectTo,
        },
    });
};

const LandingPage = ({ location: { hash }, history }) => {
    const {
        state: { isAuthenticated },
        dispatch,
    } = useContext(SigninContext);

    useEffect(() => {
        /**
         * PENG-476: open login modal if not signed in and
         * get redirected from the download page
         */
        if (hash === '#download') {
            openSigninModalWithRedirectTo(dispatch, '/download')();
            history.replace({
                pathname: '/',
            });
        }
    }, [dispatch, hash, history]);

    return (
        <PageWrapper backgroundColor="#f4f4f4">
            <PageMainContent maxWidth={1440} backgroundColor="#f4f4f4">
                <PageSection noSidePadding>
                    <StyledMainHeading level="1" serif>
                        Our commitment to privacy
                    </StyledMainHeading>
                    <Spacer margin="md">
                        <SeparatorLine width={16} lgSpacingTop="40" lgSpacingBottom="40" />
                    </Spacer>
                    <StyledLogoFlex className="logos" display="flex" justifyContent="center">
                        <img src={zillowLogo} alt="zillow-logo" />
                        <img src={truliaLogo} alt="trulia-logo" />
                        <img src={hotpadsLogo} alt="hotpads-logo" />
                        <img src={streeteasyLogo} alt="streeteasy-logo" />
                    </StyledLogoFlex>
                    <StyledHeroFlex display="flex" image={landingHeroImage}>
                        <div className="privacy-hero-image exclude-from-algorithmic-darkening" />
                        <div className="privacy-hero-description">
                            <Heading
                                style={{ fontSize: '40px' }}
                                level="2"
                                serif
                                margin={{ default: 'sm', lg: 'lg' }}
                            >
                                Finding a home is a personal journey.
                            </Heading>
                            <Paragraph margin="md" mediaQuery={{ lg: { spacing: 'xl' } }}>
                                No one understands that better than we do. And we're committed to
                                being transparent about how we use your data to help get you into a
                                home you'll love. Our goal is to give you the tools you need to be
                                in control of your privacy.
                            </Paragraph>
                        </div>
                    </StyledHeroFlex>
                </PageSection>

                <PageSection maxWidth={792} noSidePadding>
                    <StyledHeading level="2" serif>
                        Here's the data we use and why
                    </StyledHeading>
                    <SeparatorLine width={16} lgSpacingTop="32" lgSpacingBottom="40" />
                    <CollapsibleCard {...COLLECT_INFO_CARD} />
                    <CollapsibleCard {...USE_INFO_CARD} />
                    <CollapsibleCard {...SHARE_INFO_CARD} />
                </PageSection>

                <PageSection noSidePadding>
                    <StyledHeading level="2" serif>
                        You're in control of your privacy
                    </StyledHeading>
                    <SeparatorLine width={16} lgSpacingBottom="40" />
                    <StyledParagraph>
                        We've built tools that allow you to see and manage your personal data.
                    </StyledParagraph>
                    <StyledActionCardsFlex display="flex">
                        <ActionCard {...MANAGE_PRIVACY_PREFERENCES_ACTION_CARD} shouldIgnoreLogin />
                        <ActionCard
                            {...EXPORT_DATA_ACTION_CARD}
                            isAuthenticated={isAuthenticated}
                            openSigninModal={openSigninModalWithRedirectTo(dispatch, '/export')}
                        />
                    </StyledActionCardsFlex>
                    <StyledActionCardsFlex display="flex">
                        <ActionCard
                            {...DELETE_DATA_ACTION_CARD}
                            isAuthenticated={isAuthenticated}
                            openSigninModal={openSigninModalWithRedirectTo(dispatch, '/delete')}
                        />
                        <ActionCard {...UPDATE_YOUR_DATA_ACTION_CARD} shouldIgnoreLogin />
                    </StyledActionCardsFlex>
                </PageSection>

                <PageSection>
                    <StyleReadFullPolicyWrapper>
                        <Text fontType="bodySmall">
                            <strong>
                                Privacy Metrics for year{' '}
                                <NavLink activeClassName="selected" to="/metrics#2020">
                                    2020,{' '}
                                </NavLink>
                                <NavLink activeClassName="selected" to="/metrics#2021">
                                    2021,{' '}
                                </NavLink>
                                <NavLink activeClassName="selected" to="/metrics#2022">
                                    2022,{' '}
                                </NavLink>
                                <NavLink activeClassName="selected" to="/metrics#2023">
                                    2023
                                </NavLink>
                            </strong>
                        </Text>
                    </StyleReadFullPolicyWrapper>
                    <StyleReadFullPolicyWrapper>
                        <Text fontType="bodySmall">
                            <strong>
                                Read our full{' '}
                                <a
                                    href="https://www.zillowgroup.com/terms-of-use-privacy-policy/zg-privacy-policy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    privacy policy
                                </a>
                            </strong>
                        </Text>
                    </StyleReadFullPolicyWrapper>
                </PageSection>
            </PageMainContent>
        </PageWrapper>
    );
};

export default withRouter(LandingPage);
